import React, { useMemo } from "react";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { PersonIcon } from "@radix-ui/react-icons";
import { Badge } from "../../ui/Badge";
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from "../../ui/Tooltip";
import { HandThumbUpIcon, HandThumbDownIcon } from "@heroicons/react/24/outline";

const DisplayConversation = ({ conversation, isAdminView }) => {
  const toolCalls = conversation.toolCalls
    ? conversation.toolCalls.reduce((acc, call) => {
        acc[call.toolName] = (acc[call.toolName] || 0) + 1;
        return acc;
      }, {})
    : {};

  const formatDuration = (duration) => {
    try {
      const [hours, minutes, seconds] = duration.split(":").map(Number);
      if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        throw new Error("Invalid duration format");
      }
      const totalMinutes = hours * 60 + minutes + seconds / 60;
      return totalMinutes < 1 ? "<1min" : `${Math.round(totalMinutes)}min`;
    } catch (error) {
      console.error("Error formatting duration:", error, "Duration:", duration);
      return "<1min";
    }
  };

  const truncatedFirstMessage = conversation.firstMessageText ? (conversation.firstMessageText.length > 75 ? conversation.firstMessageText.substring(0, 72) + "..." : conversation.firstMessageText) : "";

  const hasSubConversations = conversation.subConversations && conversation.subConversations.length > 0;

  // Calculate sub-agent summary for badges and unique sub-agents for avatars
  const { subAgentSummary, uniqueSubAgents } = useMemo(() => {
    if (!hasSubConversations) return { subAgentSummary: [], uniqueSubAgents: [] };

    const agentSet = new Set(); // Stores unique sub-agent names
    const agentMap = new Map(); // Stores unique sub-agents, preserving order

    // Iterate through sub-conversations once
    for (const subConv of conversation.subConversations) {
      // Store unique sub-agent names
      agentSet.add(subConv.agentName);
      // Store unique sub-agents for avatars
      if (!agentMap.has(subConv.agentName)) {
        agentMap.set(subConv.agentName, subConv);
      }
    }

    // Create summary for badges
    const summary = Array.from(agentSet).map((agentName) => ({
      agentName,
      display: agentName,
    }));

    return {
      subAgentSummary: summary,
      uniqueSubAgents: Array.from(agentMap.values()).slice(0, 10), // Limit to 10 avatars
    };
  }, [conversation.subConversations, hasSubConversations]);

  // Calculate total tokens by model
  const tokensByModel = useMemo(() => {
    const tokenMap = {
      [conversation.agentModel]: conversation.totalTokens,
    };

    if (hasSubConversations) {
      conversation.subConversations.forEach((subConv) => {
        if (tokenMap[subConv.agentModel]) {
          tokenMap[subConv.agentModel] += subConv.totalTokens;
        } else {
          tokenMap[subConv.agentModel] = subConv.totalTokens;
        }
      });
    }

    return tokenMap;
  }, [conversation, hasSubConversations]);

  const totalTokens = useMemo(() => Object.values(tokensByModel).reduce((sum, tokens) => sum + tokens, 0), [tokensByModel]);

  const toolCallSummary = Object.entries(toolCalls)
    .filter(([tool]) => tool !== "call_agent")
    .map(([tool, count]) => ({
      tool,
      count,
      display: `${count} ${tool} tool${count !== 1 ? "s" : ""}`,
    }));

  const formatFeedbackDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  return (
    <TooltipProvider>
      <div className="p-4 bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md transition-shadow">
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center space-x-3">
            <div className="flex -space-x-4">
              <div className="z-50 flex-shrink-0 w-10 h-10">
                {conversation.agentLogoUrl ? (
                  <img src={conversation.agentLogoUrl} alt={conversation.agentName} className="w-10 rounded-full object-cover border-2 border-white" />
                ) : (
                  <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-full border-2 border-white">
                    <PersonIcon className="w-6 h-6 text-gray-400" />
                  </div>
                )}
              </div>
              {uniqueSubAgents.map((subConv, index) => (
                <div key={subConv.agentName} className={`z-${50 - index - 1} flex-shrink-0 w-10 h-10 bg-white rounded-full -ml-4`}>
                  {subConv.agentLogoUrl ? (
                    <img src={subConv.agentLogoUrl} alt={subConv.agentName} className="w-full h-full rounded-full object-cover border-2 border-white bg-gray-100" />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center bg-gray-200 rounded-full border-2 border-white">
                      <PersonIcon className="w-6 h-6 text-gray-400" />
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div>
              <p className="text-sm font-medium text-gray-900">{conversation.agentName || `AI Agent`}</p>
              <p className="text-xs text-gray-500">
                {Object.entries(tokensByModel).map(([model, tokens], index, array) => (
                  <span key={model}>
                    {model}: {tokens} tokens
                    {index < array.length - 1 ? " | " : ""}
                  </span>
                ))}
              </p>
            </div>
          </div>
          <Tooltip>
            <TooltipTrigger>
              <span className="text-xs text-gray-500">
                {formatDistanceToNow(new Date(conversation.updatedAt), { addSuffix: true })}, for {formatDuration(conversation.duration)}
              </span>
            </TooltipTrigger>
            <TooltipContent>{new Date(conversation.updatedAt).toLocaleString()}</TooltipContent>
          </Tooltip>
        </div>
        <p className="text-sm text-gray-700 mb-2">
          {isAdminView ? (
            <>
              <span className="font-semibold">{conversation.userEmail}</span> started a thread:{" "}
            </>
          ) : (
            <>You started a thread: </>
          )}
          <span className="italic">{truncatedFirstMessage}</span>
        </p>
        <div className="flex flex-wrap gap-2 my-3">
          {subAgentSummary.map(({ agentName, display }) => (
            <Badge key={agentName} variant="default">
              {display}
            </Badge>
          ))}
          <Badge variant="secondary">
            {conversation.totalMessageCount} message{conversation.totalMessageCount !== 1 ? "s" : ""}
          </Badge>
          {conversation.attachmentCount > 0 && (
            <Badge variant="outline">
              {conversation.attachmentCount} attachment{conversation.attachmentCount !== 1 ? "s" : ""}
            </Badge>
          )}
          {toolCallSummary.map(({ tool, display }) => (
            <Badge key={tool} variant="outline">
              {display}
            </Badge>
          ))}
        </div>
        <div className="flex flex-wrap items-center justify-between gap-3 mt-3">
          <div>
            {conversation.totalFeedback > 0 && (
              <Tooltip delayDuration={0}>
                <TooltipTrigger className="flex items-center gap-3">
                  <div className="flex items-center gap-1.5">
                    <HandThumbUpIcon className="w-4 h-4 text-green-600" />
                    <span className="font-medium text-green-600">{conversation.totalHelpful}</span>
                  </div>
                  <div className="flex items-center gap-1.5">
                    <HandThumbDownIcon className="w-4 h-4 text-red-500" />
                    <span className="font-medium text-red-500">{conversation.totalNotHelpful}</span>
                  </div>
                  <span className="text-gray-400 text-xs">({Math.round((conversation.totalHelpful / conversation.totalFeedback) * 100)}% helpful)</span>
                </TooltipTrigger>
                <TooltipContent className="bg-white text-gray-900 border border-gray-200 shadow-lg rounded-lg w-64 p-2" sideOffset={5}>
                  <div className="space-y-2">
                    {conversation.feedbackData?.feedback_items.map((feedback, index) => (
                      <div key={index} className="flex items-start gap-2 text-sm">
                        {feedback.is_helpful ? <HandThumbUpIcon className="w-4 h-4 text-green-600 flex-shrink-0 mt-0.5" /> : <HandThumbDownIcon className="w-4 h-4 text-red-500 flex-shrink-0 mt-0.5" />}
                        <div className="flex-1">
                          <div className="text-gray-700">{feedback.user_email}</div>
                          <div className="text-gray-500 text-xs">{formatFeedbackDate(feedback.created_at)}</div>
                          {feedback.comment && <div className="text-gray-600 mt-1">{feedback.comment}</div>}
                        </div>
                      </div>
                    ))}
                  </div>
                </TooltipContent>
              </Tooltip>
            )}
          </div>

          <Link to={`/chat/${conversation.id}`} className="text-sm text-blue-600 hover:text-blue-800 font-medium">
            View Conversation
          </Link>
        </div>
      </div>
    </TooltipProvider>
  );
};

export default DisplayConversation;
