import { useState } from 'react';

const PDFTester = () => {
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setResult(null);

    const formData = new FormData();
    const fileInput = e.target.elements.pdfFile;
    const pageLimit = e.target.elements.pageLimit.value;

    formData.append('file', fileInput.files[0]);

    try {
      const url = `/api/transformers/pdfToPNG${pageLimit ? `?limit=${pageLimit}` : ''}`;
      const response = await fetch(url, {
        method: 'POST',
        body: formData
      });

      const data = await response.json();
      
      if (response.ok) {
        setResult(data);
      } else {
        setError(data.error || 'An error occurred');
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">PDF to PNG Converter Test</h2>
      
      <form onSubmit={handleSubmit} className="space-y-4 mb-6">
        <div>
          <label htmlFor="pdfFile" className="block mb-2">Select PDF File:</label>
          <input
            type="file"
            id="pdfFile"
            accept=".pdf"
            required
            className="block w-full border border-gray-300 rounded p-2"
          />
        </div>
        
        <div>
          <label htmlFor="pageLimit" className="block mb-2">Page Limit (optional):</label>
          <input
            type="number"
            id="pageLimit"
            min="1"
            className="block w-full border border-gray-300 rounded p-2"
          />
        </div>
        
        <button
          type="submit"
          disabled={loading}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:bg-blue-300"
        >
          {loading ? 'Converting...' : 'Convert'}
        </button>
      </form>

      {error && (
        <div className="text-red-500 mb-4">
          Error: {error}
        </div>
      )}

      {result && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold">Conversion Results:</h3>
          <p>Total Pages: {result.total_pages}</p>
          <p>Pages Converted: {result.pages_converted}</p>
          
          <div className="space-y-6">
            {result.images.map((img, i) => (
              <div key={i} className="border p-4 rounded">
                <h4 className="font-medium mb-2">Page {img.page}</h4>
                <img
                  src={`data:image/png;base64,${img.data}`}
                  alt={`Page ${img.page}`}
                  className="max-w-full"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default PDFTester;