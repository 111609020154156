import React, { useState } from "react";
import { TableIcon, ChevronDownIcon, ChevronUpIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

export const appendGoogleSheetSchema = {
  append_google_sheet: {
    parameters: {
      type: "object",
      properties: {
        sheet_id: {
          type: "string",
          description: "The ID of the Google Sheet to append to",
        },
        sheet_name: {
          type: "string",
          description: "The name of the sheet/tab. If None, uses the first sheet.",
        },
        values: {
          type: "array",
          items: {
            type: "array",
            items: {
              type: "string"
            }
          },
          description: "Array of values to append as new row(s)",
        },
        range: {
          type: "string",
          description: "Optional range specification (e.g., 'A:C'). Defaults to 'A:A'",
        },
      },
    },
    description: "Append one or more rows to a specified Google Sheet",
    required: ["sheet_id", "values"],
  },
};

export const toolMetadata = {
  name: "append_google_sheet",
  displayName: "Append to Google Sheet",
  description: "Append data to a specified Google Sheet",
  requiredIntegration: "google-sheets",
};

const AppendGoogleSheet = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready
  const isReady = result && result.result && (result.result.status === "success" || result.result.status === "error");

  const renderResults = (content) => {
    return (
      <div className="space-y-2">
        <div className="text-sm text-gray-600">
          {content.message}
        </div>
        {content.updatedRange && (
          <div className="text-xs text-gray-500">
            Updated Range: {content.updatedRange}
          </div>
        )}
        {content.updatedRows > 0 && (
          <div className="text-xs text-gray-500">
            Rows Updated: {content.updatedRows}
          </div>
        )}
        {content.updatedCells > 0 && (
          <div className="text-xs text-gray-500">
            Cells Updated: {content.updatedCells}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <TableIcon className="w-5 h-5" />}
          <span className="font-medium">
            Appending to Google Sheet
            {args.range ? ` (Range: ${args.range})` : ""}
          </span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.result.status === "success" ? (
            renderResults(result.result.content)
          ) : (
            <div className="text-red-600">
              Error appending to Google Sheet: {result.result.message || "Unknown error"}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AppendGoogleSheet;