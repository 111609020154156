// Export individual tools
import ScrapeUrl, { scrapeUrlSchema, toolMetadata as scrapeUrlMetadata } from "./ScrapeUrl";
import AskWeb, { askWebSchema, toolMetadata as askWebMetadata } from "./AskWeb";
import SearchSnowflake, { searchSnowflakeSchema, toolMetadata as searchSnowflakeMetadata } from "./SearchSnowflake";
import SnowflakeSql, { snowflakeSqlSchema, toolMetadata as snowflakeSqlMetadata } from "./SnowflakeSql";
import PostgresSql, { postgresSqlSchema, toolMetadata as postgresSqlMetadata } from "./PostgresSql";
import HybridSearchPG, { hybridSearchPGSchema, toolMetadata as hybridSearchPGMetadata } from "./HybridSearchPG";
import KeywordSearchPG, { keywordSearchPGSchema, toolMetadata as keywordSearchPGMetadata } from "./KeywordSearchPG";
import SemanticSearchPG, { semanticSearchPGSchema, toolMetadata as semanticSearchPGMetadata } from "./SemanticSearchPG";
import SendEmail, { sendEmailSchema, toolMetadata as sendEmailMetadata } from "./SendEmail";
import CallAgent, { callAgentSchema, toolMetadata as callAgentMetadata } from "./CallAgent";
import SearchGoogle, { searchGoogleSchema, toolMetadata as searchGoogleMetadata } from "./SearchGoogle";
import ExecutePython, { executePythonSchema, toolMetadata as executePythonMetadata } from "./ExecutePython";
import CallApi, { callApiSchema, toolMetadata as callApiMetadata } from "./CallApi";
import ReadGoogleSheet, { readGoogleSheetSchema, toolMetadata as readGoogleSheetMetadata } from "./ReadGoogleSheet";
import AppendGoogleSheet, { appendGoogleSheetSchema, toolMetadata as appendGoogleSheetMetadata } from "./GSheetAddRow";

export const tools = [
  { Component: ScrapeUrl, schema: scrapeUrlSchema, metadata: scrapeUrlMetadata },
  { Component: AskWeb, schema: askWebSchema, metadata: askWebMetadata },
  { Component: CallApi, schema: callApiSchema, metadata: callApiMetadata },
  { Component: SearchSnowflake, schema: searchSnowflakeSchema, metadata: searchSnowflakeMetadata },
  { Component: SnowflakeSql, schema: snowflakeSqlSchema, metadata: snowflakeSqlMetadata },
  { Component: PostgresSql, schema: postgresSqlSchema, metadata: postgresSqlMetadata },
  { Component: HybridSearchPG, schema: hybridSearchPGSchema, metadata: hybridSearchPGMetadata },
  { Component: KeywordSearchPG, schema: keywordSearchPGSchema, metadata: keywordSearchPGMetadata },
  { Component: SemanticSearchPG, schema: semanticSearchPGSchema, metadata: semanticSearchPGMetadata },
  { Component: SendEmail, schema: sendEmailSchema, metadata: sendEmailMetadata },
  { Component: CallAgent, schema: callAgentSchema, metadata: callAgentMetadata },
  { Component: SearchGoogle, schema: searchGoogleSchema, metadata: searchGoogleMetadata },
  { Component: ExecutePython, schema: executePythonSchema, metadata: executePythonMetadata },
  { Component: ReadGoogleSheet, schema: readGoogleSheetSchema, metadata: readGoogleSheetMetadata },
  { Component: AppendGoogleSheet, schema: appendGoogleSheetSchema, metadata: appendGoogleSheetMetadata },
];

export const allToolSchemas = tools.map((tool) => tool.schema);

export const getToolsJson = (selectedTools) => {
  return tools.reduce((acc, tool) => {
    if (selectedTools[tool.metadata.name]) {
      return { ...acc, ...tool.schema };
    }
    return acc;
  }, {});
};

export const toolNames = tools.map((tool) => tool.metadata.name);

export { ScrapeUrl, AskWeb, SearchGoogle, CallApi, SearchSnowflake, PostgresSql, HybridSearchPG, KeywordSearchPG, SemanticSearchPG, SendEmail, ExecutePython };
