import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from "react";
import useSWR from "swr";
import { usePostHog } from "posthog-js/react";
import { useAuth } from "@clerk/clerk-react";
import { useAuthenticatedSWR } from "../hooks/use-authenticated-swr";
import { ModelProviders } from "../components/ControlHub/ModelProviders";
import { SupportedIntegrations } from "../components/ControlHub/SupportedIntegrations";

const AIContext = createContext();

export const AIContextProvider = ({ children }) => {
  const { getToken, isLoaded, isSignedIn, userId, orgId } = useAuth();
  const posthog = usePostHog();
  const [state, setState] = useState({
    userInfo: null,
    agents: [],
    models: [],
    integrations: [],
    error: null,
    isAdmin: false,
    currentOrg: null,
  });

  // SWR hooks for fetching user data and agents
  const { data: userData, error: userError, mutate: mutateUser } = useAuthenticatedSWR(`/api/getUser?orgId=${orgId}`);
  const { data: agentsData, error: agentsError, mutate: mutateAgents } = useAuthenticatedSWR(`/api/agents/getAgents?orgId=${orgId}`);

  // Update state when SWR data changes
  useEffect(() => {
    if (userData && orgId) {
      const currentOrg = userData.organizations.find((org) => org.id === orgId);

      if (currentOrg) {
        const updatedModels = ModelProviders.map((model) => ({
          ...model,
          isConnected: !!currentOrg.connections[model.key],
        }));

        const updatedIntegrations = SupportedIntegrations.map((integration) => {
          if (integration.requiresOAuth) {
            // Check OAuth scopes from the first external account
            const hasRequiredScopes = integration.scopes?.every(
              scope => userData.externalAccounts[0]?.approvedScopes.includes(scope)
            );
            return {
              ...integration,
              isConnected: !!hasRequiredScopes
            };
          }
          // Regular connection check for non-OAuth integrations
          return {
            ...integration,
            isConnected: !!currentOrg.connections[integration.key],
          };
        });

        const isAdmin = currentOrg.role === "org:admin";

        setState((prev) => ({
          ...prev,
          userInfo: userData,
          models: updatedModels,
          integrations: updatedIntegrations,
          isAdmin: isAdmin,
          currentOrg: currentOrg,
        }));

        posthog?.identify(userId, {
          email: userData.email,
          firstName: userData.firstName,
          lastName: userData.lastName,
          organization: currentOrg.name,
        });
      }
    }
  }, [userData, userId, orgId, posthog]);

  useEffect(() => {
    if (agentsData) {
      setState((prev) => ({
        ...prev,
        agents: agentsData,
      }));
    }
  }, [agentsData]);

  // Combine errors
  useEffect(() => {
    setState((prev) => ({
      ...prev,
      error: userError || agentsError || null,
    }));
  }, [userError, agentsError]);

  const contextValue = useMemo(
    () => ({
      ...state,
      isLoadingUser: !userData && !userError,
      isLoadingAgents: !agentsData && !agentsError,
      userId,
      fetchUserData: mutateUser,
      fetchAgents: mutateAgents,
    }),
    [state, userData, userError, agentsData, agentsError, userId, mutateUser, mutateAgents, orgId]
  );

  console.log("Rendering AIContextProvider. userInfo:", state.userInfo, "userId:", userId, "agents:", state.agents);

  return <AIContext.Provider value={contextValue}>{children}</AIContext.Provider>;
};

export const useAI = () => {
  const context = useContext(AIContext);
  if (context === undefined) {
    throw new Error("useAI must be used within an AIContextProvider");
  }
  return context;
};
