import React, { useState } from "react";
import { MagnifyingGlassIcon, CodeIcon, ChevronDownIcon, ChevronUpIcon, TableIcon } from "@radix-ui/react-icons";
import { IconSpinner } from "../../ui/Icons";

export const readGoogleSheetSchema = {
  read_google_sheet: {
    parameters: {
      type: "object",
      properties: {
        sheet_id: {
          type: "string",
          description: "The ID of the Google Sheet to read from",
        },
        sheet_name: {
          type: "string",
          description: "The name of the sheet/tab. If None, uses the first sheet.",
        },
        startRow: {
          type: "number",
          description: "Starting row number (1-based indexing). Defaults to 1",
        },
        endRow: {
          type: "number",
          description: "Ending row number (inclusive). Defaults to last row",
        },
        columns: {
          type: "array",
          items: {
            type: "string"
          },
          description: "Array of column names to fetch. Defaults to all columns",
        },
      },
    },
    description: "Read data from a specified Google Sheet",
    required: ["sheet_id"],
  },
};

export const toolMetadata = {
  name: "read_google_sheet",
  displayName: "Read Google Sheet",
  description: "Read data from a specified Google Sheet",
  requiredIntegration: "google-sheets",
};

const ReadGoogleSheet = ({ invocation }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showRawData, setShowRawData] = useState(false);

  if (!invocation) {
    return null;
  }

  const { args, result } = invocation;

  // Determine if the result is ready by checking if the result object exists and has the necessary properties
  const isReady = result && result.result && (result.result.status === "success" || result.result.status === "error");

  const renderResults = (content) => {
    return (
      <div className="space-y-4">
        {content.data && (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {content.headers.map((header, index) => (
                    <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {content.data.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cell, cellIndex) => (
                      <td key={cellIndex} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {cell}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="border rounded-lg overflow-hidden">
      <div 
        className={`flex items-center justify-between p-3 bg-white ${isReady ? 'cursor-pointer' : ''}`}
        onClick={() => isReady && setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-2">
          {!isReady ? <IconSpinner className="w-5 h-5" /> : <TableIcon className="w-5 h-5" />}
          <span className="font-medium">Reading Google Sheet{args.columns ? ` (Columns: ${args.columns.join(", ")})` : ""}</span>
        </div>
        <div className="flex items-center">
          {isReady && (
            isExpanded ? <ChevronUpIcon className="w-5 h-5" /> : <ChevronDownIcon className="w-5 h-5" />
          )}
        </div>
      </div>
      
      {isReady && isExpanded && (
        <div className="p-4 bg-white">
          {result.result.status === "success" ? (
            renderResults(result.result.content)
          ) : (
            <div className="text-red-600">Error reading Google Sheet: {result.result.message || "Unknown error"}</div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReadGoogleSheet;